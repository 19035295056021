<template>
  <div class="filter">
    <div class="categories" :class="{ show: true }">
      <g-button
        v-for="cat in categories"
        :key="cat"
        :class="{ active: cat === category }"
        @click="category = cat"
        noHover
      >
        {{ cat === 'All' ? 'All' : cat }}
      </g-button>
    </div>
  </div>
  <div class="results-container">
    <div class="alphabet">
      <div v-for="(l, i) in letters" :key="l" :class="l.class" @click="letter = i">
        {{ l.char }}
      </div>
    </div>
    <div ref="results" class="results">
      <template v-for="store in filteredStores">
        <slot v-bind="store" />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { getLocaleValue } from 'glooh-globals/src/utils/fuse';
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    stores: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    filteredStores(): any[] {
      return [...this.stores].filter((store: any) => {
        return this.category === 'All' || store.categories.indexOf(this.category) !== -1;
      });
    },
    letters(): any[] {
      return new Array(27).fill(0).map((value, index) => {
        const storeIndex = this.filteredStores.findIndex((s: any) => {
          const title = getLocaleValue(s?.title);
          if (!title) return false;
          const _index = title[0].toUpperCase().charCodeAt(0) - 65;
          return _index === index;
        });
        return {
          char: index === 26 ? '#' : String.fromCharCode(65 + index),
          storeIndex,
          class: {
            char: true,
            active: index === this.letter,
            inactive: storeIndex === -1,
          },
        };
      });
    },
    categories(): string[] {
      return [
        'All',
        ...this.stores
          .reduce((acc: Array<string>, curr: any) => {
            for (const cat of curr.categories || []) {
              if (acc.indexOf(cat) === -1) acc.push(cat);
            }
            return acc;
          }, [])
          .sort(),
      ];
    },
  },
  data: function () {
    return {
      category: 'All',
      letter: -1,
      routes: [
        {
          params: { filter: 'all' },
          text: this.$t('shops.byAll'),
        },
        {
          params: { filter: 'category' },
          text: this.$t('shops.byCategory'),
        },
        '-',
        {
          name: 'search',
          text: this.$t('shops.search'),
          icon: 'nav-search',
          params: { result: 'all' },
        },
      ],
    };
  },
  methods: {
    goToLetter() {
      const letter = this.letters[this.letter];
      if (letter && letter.storeIndex >= 0) {
        const component = this.$refs.results as any;
        if (!component) return;
        const scrollTo = component.children[letter.storeIndex];
        scrollTo?.scrollIntoView({ behavior: 'smooth' });
        /*const y = scrollTo.offsetTop;
        component.scroll({
          top: y,
          behavior: 'smooth'
        });*/
        //component?.instance?.update();
        //component?.instance?.slideTo(letter.storeIndex);
      }
    },
  },
  watch: {
    $route() {
      if (this.$route.params.filter === 'all') {
        this.category = 'All';
      }
    },
    stores() {
      this.category = 'All';
    },
    filteredStores() {
      const component = this.$refs.swiper as any;
      component?.instance?.update();

      this.goToLetter();
    },
    letter() {
      this.goToLetter();
    },
    letters: {
      handler() {
        if (this.letter === -1 || this.letters[this.letter].class.inactive) {
          this.letter = this.letters.findIndex((l: any) => !l.class.inactive);
        }
      },
      immediate: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.filter {
  width: 100%;
  padding: 0 2rem;

  --button-spacing: 0.25rem;

  .categories {
    --button-padding: 0.5rem 1rem;
    --button-background-active: var(--theme-color);

    display: flex;
    align-items: flex-start;
    height: 0;
    overflow: auto;
    gap: 1vmax;

    transition: height 0.25s ease-in-out;

    &.show {
      height: 6rem;
    }

    .g-btn {
      margin-top: 1vmax;
      font-size: 1.5vmax;
      &.active {
        background: var(--theme-color);
        color: white;
      }
    }
  }

  :deep(.nav-wrapper) {
    --button-padding: 1vmax 0;
    --navbar-anchor-background: transparent;
    --navbar-display: inline-flex;

    .navbar {
      border-bottom: 0.2vmax solid #ddd;
      justify-content: flex-start;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 1.2vmax;
      gap: 2vmax;
    }

    .background {
      z-index: 1;
      &:after {
        content: ' ';

        position: absolute;
        left: 0;
        right: 0;
        top: calc(100%);
        height: 0.2vmax;
        background: var(--theme-color);
      }
    }
  }
}

.results-container {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  overflow: hidden;
  gap: 2rem;
  padding: 0 2rem;

  .results {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(2, calc(50% - 1rem));
    grid-auto-rows: 216px;

    align-content: flex-start;
    justify-content: space-between;

    flex: 1;
    overflow: hidden;
    overflow-y: auto;

    :deep(.swiper-slide) {
      width: 100%;
      height: 100%;
    }
  }
}

.alphabet {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  .char {
    display: inline-flex;
    font-size: 1.25rem;
    line-height: 1.25rem;
    font-weight: bold;
    color: #999;

    align-items: center;
    justify-content: center;

    width: 2.25rem;
    height: 2.25rem;

    &.inactive {
      pointer-events: none;
      opacity: 0.25;
    }

    &.active {
      pointer-events: all;
      color: #fff;
      background: var(--theme-color);
    }
  }
}

.swiper {
  --swiper-content-background: transparent;
  --swiper-caption-background: transparent;

  width: 100%;

  :deep(.swiper-container) {
    .swiper-slide {
      background: #f0f0f0;
      margin: 1vmax;
      transition: all 0.25s ease-in-out;

      .caption {
        color: black;
        bottom: 0;
      }

      &--image img {
        width: 40%;
        height: 40%;
        object-fit: contain;
      }
    }
  }
}
</style>
